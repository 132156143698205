@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply font-extrabold text-3xl sm:text-4xl lg:text-5xl tracking-tight;
  }

  h2 {
    @apply font-bold text-2xl sm:text-3xl lg:text-4xl tracking-tight;
  }

  h3 {
    @apply text-xl sm:text-2xl lg:text-3xl tracking-tight;
  }

  h4 {
    @apply sm:text-lg lg:text-xl tracking-tight;
  }

  h5 {
    @apply text-sm sm:text-base lg:text-lg tracking-tight;
  }
}

@layer utilities {
  .no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .h-s-screen {
    height: 100vh;
    height: 100svh;
  }
}

.grecaptcha-badge {
  visibility: hidden !important;
}